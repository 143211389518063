<template>
  <a-modal
    title="新建信用证还款详情"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok', {bank_id: this.bank_id}) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="信用证详情编号">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'credit_letter_detail_number',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入信用证详情编号"
                :default-active-first-option="false"
                @search="this.creditLetterSearch"
                @change="handleCreditLetterChange"
              >
                <a-select-option v-for="d in creditLetterDetailNumberList" :key="d.id" :tt="d.number">
                  {{ d.number }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="授信银行">
              <a-input v-decorator="['bank_name', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="授信银行" v-if="false">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                label-in-value
                allow-clear
                v-decorator="[
                  'bank',
                  {
                    rules: [
                      { required: true, message: '必填项，请填写信息' },
                    ],
                  },
                ]"
                placeholder="请输入银行"
                :default-active-first-option="false"
                @search="this.bankSearch"
                @change="handleBankChange"
              >
                <a-select-option v-for="d in bankList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="信用证货币金额">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['amount', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="人民币金额">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['amount_cny', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="信用证货币到人民币的汇率">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['to_cny_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="融资利率（%）">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['financing_rate', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="总计费用">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['total_amount', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="还款时间">
              <a-date-picker
                v-decorator="['repayment_date', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                show-time
                format="YYYY-MM-DD"
                placeholder="还款时间"
                value-format="yyyy-MM-DD"
              />
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea :rows="4" v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import moment from 'moment'
import pick from 'lodash.pick'
import debounce from 'lodash/debounce'
import { bank_list } from '@/api/trade_bank'
import { credit_letter_detail_list } from '@/api/trade_credit_letter_detail'

// 表单字段
const fields = ['id', 'credit_letter_detail_number', 'bank_name', 'bank', 'amount', 'amount_cny', 'to_cny_rate', 'financing_rate', 'total_amount', 'repayment_date', 'remark']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.bankSearch = debounce(this.handleBankSearch, 800)
    this.creditLetterSearch = debounce(this.handleCreditLetterDetailSearch, 800)
    return {
      form: this.$form.createForm(this),
      bank_id: -1,
      bankList: [],
      creditLetterDetailNumberList: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleBankSearch(undefined)
    this.handleCreditLetterDetailSearch(this.model.credit_letter_id)
  },
  methods: {
    moment,
    handleBankSearch (value) {
      bank_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.bankList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleBankChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleBankSearch(value.key)
      }
    },
    handleCreditLetterChange (value) {
      console.log(value)
      if (value === undefined) {
        this.form.setFieldsValue({
          bank_name: '',
          bank: ''
        })
        this.bank_id = -1
        this.handleCreditLetterDetailSearch(value)
      } else {
        this.form.setFieldsValue({
          bank_name: this.creditLetterDetailNumberList.filter(item => item.id === value)[0].bank,
          bank: '' + this.creditLetterDetailNumberList.filter(item => item.id === value)[0].bank_id
        })
        this.bank_id = this.creditLetterDetailNumberList.filter(item => item.id === value)[0].bank_id
      }
    },
    handleCreditLetterDetailSearch (value) {
      credit_letter_detail_list({ credit_letter_id: value }).then((res) => {
        const result = res.data.entries || []
        this.creditLetterDetailNumberList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
        // if (this.creditLetterDetailNumberList.length > 0) {
        //   this.creditLetterDetailNumberList = this.creditLetterDetailNumberList.map(item => {
        //     return {
        //       id: item.id,
        //       number: item.number
        //     }
        //   })
        // }
      })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>
